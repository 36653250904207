import { Converter } from '@itm/shared-frontend/lib/utils';
import getEnvVariable from './getEnvVariable';

import {
  TargetPlatform,
  PayrollStatus,
  SourceFileStatus,
  PayrollValidationSeverity,
  ReportStatus,
  SelectOption,
  PartTimeType,
  BreakInServiceType,
  ReportFrequency,
  FrequencyType,
  AuditMethod,
  FileUploadType,
  ReportType,
  EventCode,
  ExitReasonCode,
  WorkerExclusion,
  MessageFunctionCode,
  EmployeeOptOutType,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const EMPLOYER_HUB_API_URL = getEnvVariable('EMPLOYER_HUB_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const frequencyOptions: SelectOption[] = Converter.enumToSelectOptions(FrequencyType);
export const uploadStatusOptions: SelectOption[] = Converter.enumToSelectOptions(SourceFileStatus);
export const reportStatusOptions: SelectOption[] = Converter.enumToSelectOptions(ReportStatus);
export const reportTypeOptions: SelectOption[] = Converter.enumToSelectOptions(ReportType);
export const reportFrequencyOptions: SelectOption[] = Converter.enumToSelectOptions(ReportFrequency);

export const fileTypeId = 'f1bf9833-5a29-4092-9b3a-e3aa094eee51';
export const payrollStatusOptions: SelectOption[] = Converter.enumToSelectOptions(PayrollStatus);

export const payrollValidationSeverityOptions: SelectOption[] =
  Converter.enumToSelectOptions(PayrollValidationSeverity);

export const partTimeTypeOptions: SelectOption[] = Converter.enumToSelectOptions(PartTimeType);
export const breakInServiceTypeOptions: SelectOption[] = Converter.enumToSelectOptions(BreakInServiceType);

export const auditMethodOptions: SelectOption[] = [
  { label: 'Bulk Upload', value: AuditMethod.BulkUpload },
  { label: 'Manual - Salary details', value: AuditMethod.ManualSalaryDetails },
  { label: 'Manual - Part time details', value: AuditMethod.ManualPartTimeDetails },
  { label: 'Manual - Break in service details', value: AuditMethod.ManualBreakInServiceDetails },
  //following ones subject to change depends on the backend
  { label: 'Manual - Employee request', value: AuditMethod.ManualEmployeeRequest },
  { label: 'Employee request', value: AuditMethod.EmployeeRequest },
  { label: 'Bulk Upload - Employee request', value: AuditMethod.BulkUploadEmployeeRequest },
];

export const MAX_DOWNLOAD_SIZE_IN_BYTES = 52428800; // 50 MB
export const MAX_DOWNLOAD_SIZE_IN_MEGABYTES = Math.ceil(MAX_DOWNLOAD_SIZE_IN_BYTES / 1024 / 1024);

export const fileUploadTypeOptions: SelectOption[] = [
  { label: 'DB Standard', value: FileUploadType.DBStandard },
  { label: 'DC Standard', value: FileUploadType.DCStandard },
  { label: 'PAPDIS1.0', value: FileUploadType.PAPDIS1_0 },
  { label: 'PAPDIS1.1', value: FileUploadType.PAPDIS1_1 },
];

export const eventCodeMapping = {
  [EventCode.NotAnEnrolmentEvent]: 'Not an enrolment event',
  [EventCode.AutomaticEnrolment]: 'Automatic enrolment',
  [EventCode.OptIn]: 'Opt-In',
  [EventCode.VoluntaryJoiner]: 'Voluntary Joiner/Joiner',
  [EventCode.ContractualEnrolment]: 'Contractual Enrolment',
  [EventCode.JoinRequest]: 'Opt in/Join request',
  [EventCode.CyclicalReEnrolment]: 'Cyclical re-enrolment',
  [EventCode.ImmediateReEnrolment]: 'Immediate re-enrolment',
};

export const exitReasonCodeMapping = {
  [ExitReasonCode.NotProvided]: 0,
  [ExitReasonCode.EmployeeLeftEmployment]: 1,
  [ExitReasonCode.CeasedActiveMembership]: 2,
  [ExitReasonCode.Death]: 3,
  [ExitReasonCode.Retirement]: 4,
  [ExitReasonCode.FlexibleRetirement]: 5,
  [ExitReasonCode.IllHealthRetirement]: 6,
};

export const employeeRequestTypeOptions: SelectOption[] = Converter.enumToSelectOptions(EmployeeOptOutType);

export const MessageFunctionCodeMapping = {
  [MessageFunctionCode.Enrol]: 0,
  [MessageFunctionCode.InfoOnly]: 1,
  [MessageFunctionCode.AssessmentRequest]: 2,
  [MessageFunctionCode.AssessmentResponse]: 3,
  [MessageFunctionCode.WorkerInstruction]: 4,
};

export const workerExclusionMapping = {
  [WorkerExclusion.OtherOrNotKnown]: 0,
  [WorkerExclusion.NotAWorker]: 1,
  [WorkerExclusion.NotWorkingInTheUK]: 2,
  [WorkerExclusion.WorkingInTheUK]: 3,
  [WorkerExclusion.OutsideTheAgeRange]: 4,
  [WorkerExclusion.SingleEmployeeOrDirector]: 5,
  [WorkerExclusion.CeasedActiveMembershipQPSWithInLast12months]: 6,
  [WorkerExclusion.CeasedActiveMembershipQPSMoreThan12months]: 7,
  [WorkerExclusion.WULSWithInLast12months]: 8,
  [WorkerExclusion.WULMoreThan12months]: 9,
  [WorkerExclusion.WorkerInANoticePeriod]: 10,
  [WorkerExclusion.WorkerWhoHasHMRCTaxProtection]: 11,
};
